import { X } from "lucide-react";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";

interface Props {
	children: React.ReactNode;
	className?: string;
	show: boolean;
	setShow: (show: boolean) => void;
}
const Banner = ({ children, className, show, setShow }: Props) => {
	return (
		<div
			id="sticky-banner"
			tabIndex={-1}
			className={cn(
				"justify-between items-center w-full p-4 border-b border-slate-200 bg-slate-50 dark:bg-sky-700 dark:border-sky-700",
				show ? "flex" : "hidden",
				className,
			)}
		>
			{children}
			<div className="flex items-center">
				<Button
					onClick={() => setShow(false)}
					data-dismiss-target="#sticky-banner"
					type="button"
					size="icon"
					variant="ghost"
					className="p-1 rounded-md w-8 h-8"
				>
					<X size={24} />
					<span className="sr-only">Close banner</span>
				</Button>
			</div>
		</div>
	);
};

export default Banner;
