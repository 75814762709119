import { useMemo } from "react";

export function useDemo() {
	const isDemoEnvironment = useMemo(() => {
		if (typeof window === "undefined") return false;

		const hostname = window.location.hostname;
		return (
			hostname.startsWith("demo.") || hostname === "demo.patient-watch.com"
		);
	}, []);

	return isDemoEnvironment;
}
