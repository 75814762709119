// Tremor chartColors [v0.1.0]

export type ColorUtility = "bg" | "stroke" | "fill" | "text";

export const chartColors = {
	blue: {
		bg: "bg-blue-300",
		stroke: "stroke-blue-300",
		fill: "fill-blue-300",
		text: "text-blue-300",
	},
	emerald: {
		bg: "bg-emerald-300",
		stroke: "stroke-emerald-300",
		fill: "fill-emerald-300",
		text: "text-emerald-300",
	},
	violet: {
		bg: "bg-violet-300",
		stroke: "stroke-violet-300",
		fill: "fill-violet-300",
		text: "text-violet-300",
	},
	amber: {
		bg: "bg-amber-300",
		stroke: "stroke-amber-300",
		fill: "fill-amber-300",
		text: "text-amber-300",
	},
	gray: {
		bg: "bg-gray-300",
		stroke: "stroke-gray-300",
		fill: "fill-gray-300",
		text: "text-gray-300",
	},
	cyan: {
		bg: "bg-cyan-300",
		stroke: "stroke-cyan-300",
		fill: "fill-cyan-300",
		text: "text-cyan-300",
	},
	pink: {
		bg: "bg-pink-300",
		stroke: "stroke-pink-300",
		fill: "fill-pink-300",
		text: "text-pink-300",
	},
	lime: {
		bg: "bg-lime-300",
		stroke: "stroke-lime-300",
		fill: "fill-lime-300",
		text: "text-lime-300",
	},
	fuchsia: {
		bg: "bg-fuchsia-300",
		stroke: "stroke-fuchsia-300",
		fill: "fill-fuchsia-300",
		text: "text-fuchsia-300",
	},
} as const satisfies {
	[color: string]: {
		[key in ColorUtility]: string;
	};
};

export type AvailableChartColorsKeys = keyof typeof chartColors;

export const AvailableChartColors: AvailableChartColorsKeys[] = Object.keys(
	chartColors,
) as Array<AvailableChartColorsKeys>;

export const constructCategoryColors = (
	categories: string[],
	colors: AvailableChartColorsKeys[],
): Map<string, AvailableChartColorsKeys> => {
	const categoryColors = new Map<string, AvailableChartColorsKeys>();
	categories.forEach((category, index) => {
		categoryColors.set(category, colors[index % colors.length]);
	});
	return categoryColors;
};

export const getColorClassName = (
	color: AvailableChartColorsKeys,
	type: ColorUtility,
): string => {
	const fallbackColor = {
		bg: "bg-gray-300",
		stroke: "stroke-gray-300",
		fill: "fill-gray-300",
		text: "text-gray-300",
	};
	return chartColors[color]?.[type] ?? fallbackColor[type];
};

// Tremor getYAxisDomain [v0.0.0]

export const getYAxisDomain = (
	autoMinValue: boolean,
	minValue: number | undefined,
	maxValue: number | undefined,
) => {
	const minDomain = autoMinValue ? "auto" : minValue ?? 0;
	const maxDomain = maxValue ?? "auto";
	return [minDomain, maxDomain];
};

// Tremor hasOnlyOneValueForKey [v0.1.0]

export function hasOnlyOneValueForKey(
	array: any[],
	keyToCheck: string,
): boolean {
	const val: any[] = [];

	for (const obj of array) {
		if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
			val.push(obj[keyToCheck]);
			if (val.length > 1) {
				return false;
			}
		}
	}

	return true;
}
