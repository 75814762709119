import { ui } from "@/components/ui";
import type {
	StatefulTrackerViewT,
	TrackerTemplateT,
	TrackerTemplateViewT,
} from "@/types/database/trackers";

import React from "react";
import CreateTrackerButton from "./CreateTrackerButton";

interface Props {
	diaryId: string;
	trackers: StatefulTrackerViewT[];
	trackerTemplates: TrackerTemplateViewT[];
}
const NoActiveTrackerButton = ({
	trackers,
	diaryId,
	trackerTemplates,
}: Props) => {
	return (
		<>
			{trackers?.every((t) => t.end_date !== null) && (
				<ui.Card className="mb-4">
					<ui.CardHeader>
						<p className="flex text-md text-slate-600">
							To continue using the diary please add a new form:
						</p>
					</ui.CardHeader>
					<ui.CardFooter className="flex flex-col gap-y-2">
						{trackerTemplates?.map((tt: TrackerTemplateViewT) => (
							<React.Fragment key={tt.tracker_template_id}>
								<CreateTrackerButton tracker_template={tt} diaryId={diaryId} />
							</React.Fragment>
						))}
					</ui.CardFooter>
				</ui.Card>
			)}
		</>
	);
};

export default NoActiveTrackerButton;
