import { components as c } from "@/components";
import { formComponents as fc } from "@/components/form";
import { SubmitHandler, useFormContext } from "react-hook-form";
import GroupedFields from "../../../components/form/GroupedFields";
import { buttonVariants } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import { useInviteMutation } from "../../invite/api/useInviteMutation";
import InviteDialog from "../../invite/components/InviteDialog";
import InviteForm, { InviteSchemaT } from "../../invite/components/InviteForm";
import SelectRole from "../../invite/components/SelectRole";
import useInjectionOptions from "../hooks/useInjectionOptions";
import InjectionDiarySaveLabel from "./InjectionDiarySaveLabel";

interface Props {
	organisationId: string;
}
const InjectionFormFields = ({ organisationId }: Props) => {
	const form = useFormContext();

	const { methodOptions, clinicianOptions } =
		useInjectionOptions(organisationId);
	return (
		<>
			<div>
				{/* Referrer */}
				<InjectionDiarySaveLabel
					required={true}
					label="Referring Clinician"
					data={{
						referring_clinician_id: form.getValues("referring_clinician_id"),
					}}
				/>
				<fc.ComboBox
					autoFocus
					name="referring_clinician_id"
					description="The clinician who requests the injection"
					options={clinicianOptions}
					clearable={true}
					addOptionButton={
						<InviteDialog
							words="Add Option"
							className={cn(
								buttonVariants({ size: "sm", variant: "secondary" }),
							)}
						/>
					}
				/>
			</div>
			<div>
				{/* Injector */}
				<InjectionDiarySaveLabel
					label="Injecting Clinician"
					data={{
						injecting_clinician_id: form.getValues("injecting_clinician_id"),
					}}
				/>
				<fc.ComboBox
					name="injecting_clinician_id"
					description="The clinician who performs the injection"
					options={clinicianOptions}
					clearable={true}
					addOptionButton={
						<InviteDialog
							words="Add Option"
							className={cn(
								buttonVariants({ size: "sm", variant: "secondary" }),
							)}
						/>
					}
				/>
			</div>
			<div>
				{/* Method */}
				<InjectionDiarySaveLabel
					label="Injection Method"
					data={{ injection_method_id: form.getValues("injection_method_id") }}
				/>
				<fc.Select
					name="injection_method_id"
					description="How and where injection is performed"
					options={methodOptions}
					clearable={true}
				/>
			</div>
		</>
	);
};

export default InjectionFormFields;
