import {
	BarChart3,
	BookMarked,
	HeartHandshake,
	HelpCircle,
	MessageCircle,
	Pencil,
	ShieldCheck,
	Users,
} from "lucide-react";

import Lightbulb from "@/assets/icons/Lightbulb";
import FootIcon from "../../foot-watch/assets/FootIcon";
import InjectionIcon from "../../pain-watch/assets/InjectionIcon";

import type { ExternalLink, RouteT } from "@/types/zod/routes";
import type { ReactNode } from "react";

export interface Page {
	name: string;
	href: RouteT | ExternalLink;
	description?: string;
	icon?: ReactNode;
	external?: boolean;
	draft?: boolean;
	highlight?: boolean;
}

export interface NavigationItem {
	name: string;
	footerOnly?: boolean;
	pages: Page[];
	draft?: boolean;
}

export const navigationContent: NavigationItem[] = [
	{
		name: "Use Cases",
		pages: [
			{
				name: "Pain Injections",
				href: "/use-cases/pain-injections",
				description: "An automated pain diary for therapeutic injections.",
				icon: <InjectionIcon className="w-11 h-11" />,
			},
			{
				name: "Diabetic Feet",
				href: "/use-cases/diabetic-feet",
				description:
					"An AI powered photo diary for preventing diabetic foot ulcers.",
				icon: <FootIcon className="w-11 h-11" />,
			},
			{
				name: "Custom",
				href: "/custom",
				description: "Custom use cases for your clinic.",
				icon: <Lightbulb className="w-11 h-11" />,
				highlight: true,
			},
		],
	},
	// { name: 'Security', href: '/security', icon: <ShieldCheck /> },
	// { name: 'Evidence', href: '/evidence', icon: <BarChart3 /> },
	{
		name: "Learn",
		pages: [
			{
				name: "Security",
				href: "/security",
				icon: <ShieldCheck strokeWidth={1.75} />,
			},
			{
				name: "Evidence",
				href: "/evidence",
				icon: <BarChart3 strokeWidth={1.75} />,
			},
			// { name: 'Accessability', href: '/accessability' },
		],
	},

	{
		name: "Company",
		pages: [
			{
				name: "About",
				href: "/about",
				icon: <Users strokeWidth={1.75} />,
			},
			{
				name: "Story",
				href: "/story",
				icon: <BookMarked strokeWidth={1.75} />,
			},
			{
				name: "Contact",
				href: "/contact",
				icon: <MessageCircle strokeWidth={1.75} />,
			},
		],
	},
	{
		name: "Legal",
		footerOnly: true,
		pages: [
			{
				name: "Terms of Use",
				href: "https://legal.patient-watch.com/legal/terms-of-use",
				external: true,
			},
			{
				name: "Privacy Policy",
				href: "https://legal.patient-watch.com/legal/privacy-policy",
				external: true,
			},
			{
				name: "Acceptable Use Policy",
				href: "https://legal.patient-watch.com/legal/acceptable-use-policy",
				external: true,
			},
			// { name: 'Accessability Policy', href: '/support' },
			{
				name: "Data Collected",
				href: "https://legal.patient-watch.com/legal/data-collected",
				external: true,
			},
			{
				name: "Password Policy",
				href: "https://legal.patient-watch.com/legal/password-policy",
				external: true,
			},
			{
				name: "Accessibility Statement",
				href: "https://legal.patient-watch.com/legal/accessibility-statement",
				external: true,
			},
		],
	},
	{
		name: "Help",
		pages: [
			{
				name: "Support",
				href: "/support",
				icon: <HeartHandshake strokeWidth={1.75} />,
			},
			{
				name: "FAQs",
				href: "/FAQs",
				icon: <HelpCircle strokeWidth={1.75} />,
			},
		],
	},
];
