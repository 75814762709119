import { commonComponents as cc } from "@/components/common";
import { cn } from "@/lib/utils";
import type { ExtendedInjectionViewT } from "@/types/database/events";
import { useAuth } from "@/utils/auth";
import { warningColors } from "../../../utils/warningColors";
import InjectionFormTabs from "./InjectionFormTabs";
import InjectionSummary from "./InjectionSummary";

interface Props {
	diaryId: string;
	eventId: string;
	trackerId?: string;
	injectionId: string;
	injection: ExtendedInjectionViewT;
	organisationId: string;
	event: Event;
}
const InjectionDialog = (props: Props) => {
	const auth = useAuth();
	return (
		<cc.EditDialog
			locked={auth?.roles?.isPatientOnly}
			label={
				<h4
					className={cn(
						"text-semibold text-md",
						warningColors(props.injection?.is_incomplete ?? false),
					)}
				>
					Injection {props.injection?.is_incomplete && "Missing Information"}
				</h4>
			}
			info={<InjectionSummary injection={props.injection} />}
		>
			<InjectionFormTabs
				event={props.event}
				trackerId={props.trackerId}
				injection={props.injection}
				diaryId={props.diaryId}
				eventId={props.eventId}
				injectionId={props.injectionId}
				organisationId={props.organisationId}
			/>
		</cc.EditDialog>
	);
};

export default InjectionDialog;
