import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../utils/auth";

type Theme = "dark" | "light" | "system";

type ThemeProviderProps = {
	children: React.ReactNode;
	defaultTheme?: Theme;
	storageKey?: string;
};

type ThemeProviderState = {
	theme: Theme;
	setTheme: (theme: Theme) => void;
	removeBackground: () => void;
};

const initialState: ThemeProviderState = {
	theme: "system",
	setTheme: () => null,
	removeBackground: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
	children,
	defaultTheme = "system",
	storageKey = "vite-ui-theme",
	...props
}: ThemeProviderProps) {
	const auth = useAuth();
	const [theme, setTheme] = useState<Theme>(
		() => (localStorage.getItem(storageKey) as Theme) || defaultTheme,
	);

	const updateBackground = (theme: Theme) => {
		const app = window.document.getElementById("app");
		if (theme === "dark") {
			app?.classList.remove("background-light");
			app?.classList.add("background-dark");
		} else {
			app?.classList.remove("background-light");
			app?.classList.add("background-light");
		}
	};

	const removeBackground = () => {
		const app = window.document.getElementById("app");
		app?.classList.remove("background-light", "background-dark");
		app?.classList.add("background-no-gradient");
	};

	const updateTheme = (theme: Theme) => {
		// Only allowed logged in users to set a theme
		if (!auth.id) return;

		const root = window.document.documentElement;

		root.classList.remove("light", "dark");

		if (theme === "system") {
			const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
				.matches
				? "dark"
				: "light";

			// Darkmode not yet ready
			// root.classList.add(systemTheme);
			root.classList.add("light");
			return;
		}

		root.classList.add(theme);
	};

	useEffect(() => {
		updateTheme(theme);
		updateBackground(theme);
	}, [theme]);

	const value = {
		theme,
		setTheme: (theme: Theme) => {
			localStorage.setItem(storageKey, theme);
			setTheme(theme);
			updateBackground(theme);
			updateTheme(theme);
		},
		removeBackground,
	};

	return (
		<ThemeProviderContext.Provider {...props} value={value}>
			{children}
		</ThemeProviderContext.Provider>
	);
}

export const useTheme = () => {
	const context = useContext(ThemeProviderContext);

	if (context === undefined)
		throw new Error("useTheme must be used within a ThemeProvider");

	return context;
};
