import React, { useState } from "react";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../../components/ui/accordion";
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "../../components/ui/carousel";

import type { EventEntryT } from "../../types/database/events";
import AverageScoreByLabel from "./AverageScoreByLabel";
import AverageScoreByTime from "./AverageScoreByTime";
import FilterBy from "./FilterBy";
import LocationDonut from "./LocationDonut";
import MostCommonInjections from "./MostCommonInjections";

const Analytics = ({ eventEntries }: { eventEntries: EventEntryT[] }) => {
	const [filteredEvents, setFilteredEvents] =
		useState<EventEntryT[]>(eventEntries);
	return (
		<div className="flex flex-col gap-4 p-4">
			<FilterBy
				eventEntries={eventEntries}
				setFilteredEvents={setFilteredEvents}
			/>
			<div className="flex flex-row justify-between items-start gap-2 w-full">
				<LocationDonut eventEntries={filteredEvents} />
				<MostCommonInjections eventEntries={filteredEvents} type="site" />
				<MostCommonInjections eventEntries={filteredEvents} type="substance" />
			</div>
			<AverageScoreByLabel eventEntries={filteredEvents} />
		</div>
	);
};

export default Analytics;
