import { formComponents as fc } from "@/components/form";
import GroupedFields from "@/components/form/GroupedFields";
import { ui } from "@/components/ui";
import {
	createUniqueInjectionOptions,
	toFilteredUniqueInjectionOptions,
} from "@/features/injection/utils/injectionOptions";
import type { EventEntryT } from "@/types/database/events";
import { useForm, zodResolver } from "@/utils/form";
import { FilterIcon } from "lucide-react";
import { useState } from "react";
import { z } from "zod";
import { Button } from "../../components/ui/button";
import useInjectionDetailsOptions from "../injection/hooks/useInjectionDetailsOptions";

const FilterBySchemaZ = z.object({
	category: z.string().optional(),
	orthopaedic_area: z.string().optional(),
	injection_site: z.string().optional().nullable(),
	injection_subsite: z.string().optional().nullable(),
	injection_substance: z.string().optional().nullable(),
	dose: z.string().optional().nullable(),
	unit: z.string().optional().nullable(),
});

type FilterBySchemaT = z.infer<typeof FilterBySchemaZ>;

interface Props {
	eventEntries: EventEntryT[];
	setFilteredEvents: (events: EventEntryT[]) => void;
}

const FilterBy = ({ eventEntries, setFilteredEvents }: Props) => {
	const { injectionLocations, injectionContents } =
		useInjectionDetailsOptions();
	const [accordionValue, setAccordionValue] = useState<string | undefined>(
		"filters",
	);

	const form = useForm<FilterBySchemaT>({
		resolver: zodResolver(FilterBySchemaZ),
		defaultValues: {
			category: "",
			orthopaedic_area: "",
			injection_site: null,
			injection_subsite: null,
			injection_substance: null,
			dose: null,
			unit: null,
		},
	});

	const formValues = form.watch();

	const onSubmit = async (formData: FilterBySchemaT) => {
		setFilteredEvents(
			eventEntries.filter((entry) => {
				if (
					formData.orthopaedic_area &&
					entry.orthopaedic_area !== formData.orthopaedic_area
				) {
					return false;
				}
				if (
					formData.injection_site &&
					entry.injection_site !== formData.injection_site
				) {
					return false;
				}
				if (
					formData.injection_subsite &&
					entry.injection_subsite !== formData.injection_subsite
				) {
					return false;
				}
				if (
					formData.injection_substance &&
					entry.injection_substance !== formData.injection_substance
				) {
					return false;
				}
				if (formData.dose && entry.dose !== formData.dose) {
					return false;
				}
				if (formData.unit && entry.unit !== formData.unit) {
					return false;
				}
				return true;
			}),
		);
		setAccordionValue(undefined);
	};

	const renderSelectedFilters = () => {
		const filters = [];
		if (formValues.category) filters.push(`Category: ${formValues.category}`);
		if (formValues.orthopaedic_area)
			filters.push(`Area: ${formValues.orthopaedic_area}`);
		if (formValues.injection_site)
			filters.push(`Site: ${formValues.injection_site}`);
		if (formValues.injection_subsite)
			filters.push(`Subsite: ${formValues.injection_subsite}`);
		if (formValues.injection_substance)
			filters.push(`Substance: ${formValues.injection_substance}`);
		if (formValues.dose)
			filters.push(
				`Dose: ${formValues.dose}${
					formValues.unit ? ` ${formValues.unit}` : ""
				}`,
			);

		return filters.length ? (
			<div className="flex flex-wrap gap-2">
				{filters.map((filter, index) => (
					<ui.Badge key={index} variant="secondary">
						{filter}
					</ui.Badge>
				))}
			</div>
		) : (
			<span className="text-slate-500 text-sm">No filters applied</span>
		);
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 w-full">
				<div className="bg-indigo-50 dark:bg-slate-900 shadow-md px-4 py-2 border rounded-lg">
					<ui.Accordion
						type="single"
						collapsible
						value={accordionValue}
						onValueChange={setAccordionValue}
					>
						<ui.AccordionItem value="filters" className="border-none">
							<ui.AccordionTrigger className="hover:no-underline">
								<div className="flex items-center space-x-2">
									<FilterIcon size={20} />
									{renderSelectedFilters()}
								</div>
							</ui.AccordionTrigger>
							<ui.AccordionContent>
								<div className="flex flex-col gap-y-4">
									<div className="flex md:flex-row flex-col gap-x-2 gap-y-4">
										<fc.Select
											name="category"
											label="Category"
											options={[
												{ label: "Injection", value: "injection" },
												{ label: "Surgery", value: "surgery" },
												{ label: "Other", value: "other" },
											]}
										/>
										<fc.Select
											name="orthopaedic_area"
											label="Anatomic Area"
											options={createUniqueInjectionOptions(
												injectionLocations,
												"orthopaedic_area",
											)}
										/>
									</div>

									<GroupedFields>
										<fc.ComboBox
											name="injection_site"
											label="Injection Site"
											options={toFilteredUniqueInjectionOptions(
												injectionLocations,
												"injection_site",
												"orthopaedic_area",
												formValues.orthopaedic_area,
											)}
											dependsOn="orthopaedic_area"
										/>
										<fc.ComboBox
											name="injection_subsite"
											label="Injection Subsite"
											options={toFilteredUniqueInjectionOptions(
												injectionLocations,
												"injection_subsite",
												"injection_site",
												formValues.injection_site,
											)}
											dependsOn="injection_site"
										/>
									</GroupedFields>

									<GroupedFields>
										<fc.ComboBox
											name="injection_substance"
											label="Injection Substance"
											options={createUniqueInjectionOptions(
												injectionContents,
												"injection_substance",
											)}
										/>
									</GroupedFields>

									<GroupedFields>
										<fc.Select
											name="dose"
											label="Dose"
											options={toFilteredUniqueInjectionOptions(
												injectionContents,
												"dose",
												"injection_substance",
												formValues.injection_substance,
												true,
											)}
											dependsOn="injection_substance"
										/>
										<fc.Select
											side="top"
											name="unit"
											label="Unit"
											options={toFilteredUniqueInjectionOptions(
												injectionContents,
												"unit",
												"dose",
												formValues.dose,
											)}
											dependsOn="dose"
										/>
									</GroupedFields>

									<div className="flex flex-row flex-wrap gap-4 mt-2">
										<Button size="md" type="submit">
											Apply
										</Button>
										<Button
											size="md"
											variant="secondary"
											type="button"
											onClick={() => {
												form.reset();
												setFilteredEvents(eventEntries);
												setAccordionValue(undefined);
											}}
										>
											Clear
										</Button>
									</div>
								</div>
							</ui.AccordionContent>
						</ui.AccordionItem>
					</ui.Accordion>
				</div>
			</form>
		</ui.Form>
	);
};

export default FilterBy;
