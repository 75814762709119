import { DonutChart } from "@/components/tremor/DonutChart";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import _ from "lodash";
import type { AnalyticsBaseProps } from "./types";

const LocationDonut = ({ eventEntries }: AnalyticsBaseProps) => {
	const chartdata = Object.entries(_.countBy(eventEntries, "orthopaedic_area"))
		.map(([name, value]) => ({
			name,
			amount: value,
		}))
		.filter((dataPoint) => dataPoint.name !== "null");

	return (
		<Card className="w-full">
			<CardHeader>
				<CardTitle>Anatomic Areas</CardTitle>
				<CardDescription>Number of injections by anatomic area</CardDescription>
			</CardHeader>
			<CardContent>
				<DonutChart
					className="mx-auto"
					data={chartdata}
					category="name"
					value="amount"
					showLabel={true}
					valueFormatter={(number: number) => number.toString()}
				/>
			</CardContent>
		</Card>
	);
};

export default LocationDonut;
