import { ui } from "@/components/ui";
import { Separator } from "@/components/ui/separator";
import AuthWrap from "@/features/auth/components/AuthWrap";
import type { QuickstartT } from "@/types/database/profiles";
import type { RoleT } from "@/types/zod/auth";
import { Link } from "@tanstack/react-router";
import { createFileRoute } from "@tanstack/react-router";
import { ExternalLink } from "lucide-react";
import { commonComponents as cc } from "../../components/common";
import Or from "../../components/common/Or";
const NewUser = () => {
	return (
		<AuthWrap>
			<ui.Card className="mx-auto w-full max-w-md">
				<ui.CardHeader>
					<ui.CardTitle>Invitation Required</ui.CardTitle>
					<ui.CardDescription>
						Patient Watch is an invitation-only platform.
					</ui.CardDescription>
				</ui.CardHeader>
				<ui.CardContent>
					<ui.Tabs defaultValue="patient" className="w-full">
						<ui.TabsList className="grid grid-cols-2 w-full">
							<ui.TabsTrigger value="patient">For Patients</ui.TabsTrigger>
							<ui.TabsTrigger value="clinician">For Clinicians</ui.TabsTrigger>
						</ui.TabsList>
						<ui.TabsContent value="patient" className="space-y-4 mt-4">
							<div className="space-y-4 text-md text-muted-foreground">
								<p>
									If you've been invited by a clinician you should have an email
									invitation, please use the email address used there to{" "}
									<Link
										to="/confirm-account"
										className="text-indigo-600 underline underline-offset-2 hover:underline-offset-4"
									>
										confirm your account
									</Link>
									.
								</p>
								<Separator />
								<p>
									Need help?{" "}
									<Link
										to="/support"
										className="text-indigo-600 underline underline-offset-2 hover:underline-offset-4"
									>
										Contact support
									</Link>
									.
								</p>
							</div>
						</ui.TabsContent>
						<ui.TabsContent value="clinician" className="space-y-4 mt-4">
							<div className="flex flex-col items-center gap-2">
								<p className="text-md text-slate-700">
									You can try Patient Watch out with dummy data, or get in touch
									to learn to learn more.
								</p>
								<ui.Button variant="default" asChild>
									<a
										target="_blank"
										href="https://demo.patient-watch.com/try-demo"
										rel="noreferrer"
									>
										Try it out <ExternalLink size={20} className="ml-1" />
									</a>
								</ui.Button>
								<Or />
								<ui.Button variant="secondary" asChild>
									<Link to="/contact">Get in Touch</Link>
								</ui.Button>
							</div>
						</ui.TabsContent>
					</ui.Tabs>
				</ui.CardContent>
			</ui.Card>
		</AuthWrap>
	);
};

export type OrganisationSummary = {
	organisation_id: string;
	organisation_name: string;
};

export type SignupSearch = {
	role?: RoleT;
	email?: string;
	favourite_diary_templates?: string[];
	organisation?: OrganisationSummary;
	quickstart?: QuickstartT;
};

export const Route = createFileRoute("/_auth/new-user")({
	component: NewUser,
});
