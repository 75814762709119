import { commonComponents as cc } from "@/components/common";
import { ui } from "@/components/ui";
import { watches } from "@/definitions/diaryTypes";
import type { Watch } from "@/definitions/diaryTypes";
import { cn } from "@/lib/utils";
import type { StatefulDiaryT } from "@/types/database/diaries";
import { Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { BellRing } from "lucide-react";
import type React from "react";
import { useAuth } from "../../../utils/auth";
import StateCard from "../../dashboard/features/interface/components/StateCard";

interface DiaryCardProps {
	diary: StatefulDiaryT;
}

//
const DiaryOverviewCard: React.FC<DiaryCardProps> = ({ diary }) => {
	const watchType = diary.diary_type?.toUpperCase() as Watch;
	const watch = watches[watchType];
	const auth = useAuth();

	return (
		<ui.Card
			className={cn(
				"mb-4 text-sm overflow-hidden  [&_h3]:text-slate-950 [&_h4]:text-slate-600 [&_p]:text-slate-900 dark:[&_h3]:text-slate-50 dark:[&_h4]:text-slate-300 dark:[&_p]:text-slate-100",
			)}
		>
			<ui.CardHeader className="pb-0">
				<div className="flex justify-between items-start w-full">
					<h3 className="flex justify-start items-center gap-x-2 my-auto font-medium text-lg">
						{watch.icon}
						{watch.name}
						{diary.tracker_due && (
							<div className="animate-big-wiggle">
								<BellRing />
							</div>
						)}
					</h3>
					<cc.EditButton asChild={true}>
						<Link
							to="/diary/$diaryId/edit"
							params={{ diaryId: diary.diary_id }}
						>
							Edit
						</Link>
					</cc.EditButton>
				</div>
			</ui.CardHeader>
			<ui.CardContent>
				<h2 className="font-medium text-slate-800 text-xl dark:text-slate-200">
					{diary.diary_name}
				</h2>
				<h4 className="font-normal">
					{diary?.start_date
						? `Start Date: ${dayjs(diary?.start_date).format("DD MMM YYYY")}`
						: `Diary Created: ${dayjs(diary?.created_at).format(
								"DD MMM YYYY",
							)}`}
				</h4>
				<ui.Badge className="mt-2" variant="secondary">
					{diary.organisation_name}
				</ui.Badge>

				{diary.patient_id !== auth.id && (
					<ui.Badge variant="outline" className="mt-2">
						{diary.patient_info.patient_name}
					</ui.Badge>
				)}
				{/* <StateCard
					diary={diary}
					diaryType={diary.diary_type}
					diaryId={diary.diary_id}
					patientId={diary.patient_id}
				/> */}
			</ui.CardContent>
			<ui.CardFooter className="flex justify-start items-center gap-2">
				<ui.Button size="md" asChild>
					<Link
						to="/diary/$diaryId/home/new"
						params={{ diaryId: diary.diary_id }}
					>
						Open
					</Link>
				</ui.Button>
				{/* <ExplainerLink size="md" variant="secondary" words="Learn" diaryType={diary.diary_type} /> */}
			</ui.CardFooter>
		</ui.Card>
	);
};

export default DiaryOverviewCard;
