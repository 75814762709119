import { ui } from "@/components/ui";
import AnimateInView from "@/components/ui/animate-in-view";
import Highlight from "@/features/website/components/Highlight";
import DesktopMobile from "@/features/website/landing/assets/desktop-mobile-patient-watch.png";
import { Link, createFileRoute } from "@tanstack/react-router";
import { motion } from "framer-motion";

import {
	ArrowBigDown,
	ArrowBigDownDash,
	ArrowBigRightDash,
	ArrowDown,
	ArrowRightCircle,
	BookOpen,
	Brain,
	Calendar,
	CheckCheck,
	ClipboardEdit,
	Clock,
	Clock1,
	FileSpreadsheet,
	Flag,
	Hand,
	Pill,
	Quote,
	Settings2,
	Users,
	Workflow,
} from "lucide-react";
import { Fragment } from "react";
import Section from "../../features/website/components/Section";

const steps = [
	{
		step: 1,
		color: "indigo",
		icon: FileSpreadsheet,
		title: "Patients only give you the information you need",
		description:
			"Whether it's a validated questionnaire, wound photos, or exercise videos - you decide what matters for your patients' care.",
		badges: ["Questionnaire", "Consent", "Photo of Wound", "Video of Physio"],
		offset: "0%",
	},
	{
		step: 2,
		color: "pink",
		icon: Clock,
		title: "Hear from your patients when it is convenient for you",
		description:
			"From weekly check-ins after starting new medication to 6-month post-surgery reviews - you control the timing that works for your practice.",
		badges: [
			"Every Three Months for 1 Year",
			"Monday Mornings at 10 a.m.",
			"Post-Surgery: 6h, 24h, 48h",
		],
		offset: "5%",
	},
	{
		step: 3,
		color: "amber",
		icon: Workflow,
		title: "Only spend time on patients who need your help",
		description:
			"Together, we'll set up smart filters to identify the patients who need your expertise, while letting you know when things are going well.",
		badges: [
			{ icon: Flag, text: "Patient pain rising rapidly" },
			{ icon: CheckCheck, text: "Patient photo shows no changes" },
			{ icon: Clock1, text: "No response in 3 days" },
		],
		offset: "10%",
	},
	{
		step: 4,
		color: "emerald",
		icon: ArrowBigRightDash,
		title: "Common tasks become one-click actions",
		description:
			"Your common tasks - like booking follow-ups or making referrals - become one-click actions with customized letters for patients and colleagues.",
		badges: [
			{ icon: Calendar, text: "Book a follow-up appointment" },
			{ icon: Pill, text: "Send a medication update" },
			{ icon: Hand, text: "Discharge the patient" },
		],
		offset: "15%",
	},
	{
		step: 5,
		color: "indigo",
		icon: ArrowBigRightDash,
		title: "Transform patient data into actionable insights",
		description:
			"Whether you're doing an audit, checking treatment outcomes, or updating patient notes - your data is available in the format you need.",
		badges: [
			{ icon: FileSpreadsheet, text: "Download results as spreadsheet" },
			{ icon: ClipboardEdit, text: "Add to patient notes" },
			{ icon: Workflow, text: "View intervention outcomes" },
		],
		offset: "20%",
	},
];

const benefitStyles = {
	indigo: {
		iconBg: "bg-indigo-50/80",
		iconColor: "text-indigo-600",
		accentColor: "bg-indigo-500/10",
		hoverBg: "group-hover:bg-indigo-100/80",
	},
	pink: {
		iconBg: "bg-pink-50/80",
		iconColor: "text-pink-600",
		accentColor: "bg-pink-500/10",
		hoverBg: "group-hover:bg-pink-100/80",
	},
	amber: {
		iconBg: "bg-amber-50/80",
		iconColor: "text-amber-600",
		accentColor: "bg-amber-500/10",
		hoverBg: "group-hover:bg-amber-100/80",
	},
	emerald: {
		iconBg: "bg-emerald-50/80",
		iconColor: "text-emerald-600",
		accentColor: "bg-emerald-500/10",
		hoverBg: "group-hover:bg-emerald-100/80",
	},
} as const;

const benefits = [
	{
		icon: BookOpen,
		text: "Patients record their symptoms in personalised digital diary",
		description:
			"You track the symptoms that matter most for their specific condition",
		color: "indigo" as const,
	},
	{
		icon: Brain,
		text: "Patient Watch converts subjective symptoms into objective data",
		description: "Make clinical decisions easier with quantifiable insights",
		color: "pink" as const,
	},
	{
		icon: Clock,
		text: "AI filters patient diaries for efficient review",
		description: "Focus only on patients who need immediate attention",
		color: "amber" as const,
	},
	{
		icon: FileSpreadsheet,
		text: "Everything happens automatically",
		description:
			"From patient reminders to report generation - the system handles it all. Your patients stay engaged while you focus on clinical decisions, not administrative tasks.",
		color: "emerald" as const,
	},
] as const;

const Custom = () => {
	return (
		<>
			<Section className="relative pb-0 md:pb-0 lg:pb-0 overflow-hidden">
				<div className="flex flex-col justify-center items-center gap-y-12 mx-auto pt-8 pb-16">
					{/* Hero text */}
					<div className="space-y-6 max-w-[800px] text-center">
						<AnimateInView>
							<h1 className="font-bold text-4xl text-slate-900 md:text-5xl">
								Tell us what you need
								<br />
								we'll build it.
							</h1>
						</AnimateInView>
						<AnimateInView delay={0.1}>
							<p className="mx-auto max-w-[600px] text-slate-600 text-xl leading-relaxed">
								You're the clinical expert - we handle all the technical details
								to bring your vision to life.
							</p>
						</AnimateInView>
					</div>

					<ui.Button size="md">
						<Link to="/contact">Get in touch</Link>
					</ui.Button>
				</div>
			</Section>

			<Section>
				<div className="flex lg:flex-row flex-col-reverse gap-y-12 text-md">
					<div className="space-y-6 lg:w-5/12">
						<h3 className="font-semibold text-2xl text-slate-900">
							Every Patient Watch app has two sides
						</h3>
						<ol className="space-y-6">
							<li className="flex items-start gap-4">
								<div className="flex justify-center items-center bg-indigo-50 rounded-lg w-8 h-8 font-semibold text-indigo-600 shrink-0">
									1
								</div>
								<div className="space-y-1">
									<h4 className="font-medium text-slate-900">Patient Portal</h4>
									<p className="text-slate-600 leading-relaxed">
										Patient gets notified by email or SMS whenever information
										needs to be entered. This could be recording a video,
										uploading a photo, completing a questionnaire.
									</p>
								</div>
							</li>
							<li className="flex items-start gap-4">
								<div className="flex justify-center items-center bg-pink-50 rounded-lg w-8 h-8 font-semibold text-pink-600 shrink-0">
									2
								</div>
								<div className="space-y-1">
									<h4 className="font-medium text-slate-900">
										Clinician Portal
									</h4>
									<p className="text-slate-600 leading-relaxed">
										All your patients are summarised, those requiring attention
										are flagged by AI and automated actions allow quick actions
										to be taken.
									</p>
								</div>
							</li>
						</ol>
					</div>
					<img
						className="mt-8 lg:mt-0 lg:w-7/12"
						src={DesktopMobile}
						alt="Desktop and Mobile Patient Watch Software"
					/>
				</div>
			</Section>

			<div className="gap-8 md:gap-12 grid mx-auto px-2 py-8 max-w-lg">
				{benefits.map((benefit, index) => {
					const styles = benefitStyles[benefit.color];
					return (
						<AnimateInView key={benefit.text} className="relative group">
							<div
								className={`flex items-start gap-4 md:gap-8 border-2 rounded-lg md:rounded-xl border-${benefit.color}-200 hover:border-${benefit.color}-300 bg-white/75 backdrop-blur-sm p-6 border rounded-2xl transition-all`}
							>
								<div
									className={`absolute inset-0 -z-10 opacity-40 rounded-lg md:rounded-xl ${styles.accentColor} blur-2xl transition-opacity group-hover:opacity-60`}
								/>

								{/* Icon container */}
								<div
									className={`rounded-lg md:rounded-xl p-3 md:p-5 ${styles.iconBg} ${styles.hoverBg} transition-colors`}
								>
									<benefit.icon
										className={`w-8 h-8 md:w-10 md:h-10 ${styles.iconColor}`}
									/>
								</div>

								{/* Content */}
								<div className="flex-1 space-y-3">
									<h3 className="font-semibold text-lg text-slate-900 md:text-xl">
										{benefit.text}
									</h3>
									<p className="text-base text-slate-600 md:text-lg leading-relaxed">
										{benefit.description}
									</p>
								</div>
							</div>
						</AnimateInView>
					);
				})}
			</div>

			<div className="flex flex-col justify-center items-center gap-y-4 mx-auto my-16 px-6 max-w-3xl">
				<Quote className="w-10 h-10 text-slate-600" />
				<blockquote className="relative flex flex-col items-center">
					<p className="text-2xl text-center text-slate-600 italic">
						Patient Watch does what it says on the tin...
					</p>
					<p className="mt-2 text-center text-lg text-slate-600 italic">
						...it helps to keep an eye on my patients progress to allow me to
						get on with my job
					</p>
				</blockquote>
			</div>

			<Section
				title="Case Studies"
				description="See how our custom solutions are making a difference"
			>
				<div className="gap-8 grid md:grid-cols-2 mx-auto max-w-[1000px]">
					<AnimateInView>
						<ui.Card className="border-indigo-100">
							<ui.CardHeader>
								<div className="flex items-center gap-2">
									<ClipboardEdit className="text-indigo-500" />
									<ui.CardTitle>Injection Follow-Up</ui.CardTitle>
								</div>
								<ui.CardDescription>
									Automated remote monitoring system for post-injection patients
								</ui.CardDescription>
							</ui.CardHeader>
							<ui.CardFooter>
								<ui.Button size="md" asChild>
									<Link to="/use-cases/pain-injections">View Case Study</Link>
								</ui.Button>
							</ui.CardFooter>
						</ui.Card>
					</AnimateInView>

					<AnimateInView>
						<ui.Card className="border-pink-100">
							<ui.CardHeader>
								<div className="flex items-center gap-2">
									<Settings2 className="text-pink-500" />
									<ui.CardTitle>Diabetic Foot Health</ui.CardTitle>
								</div>
								<ui.CardDescription>
									Digital monitoring system for diabetic foot health management
								</ui.CardDescription>
							</ui.CardHeader>
							<ui.CardFooter>
								<ui.Button size="md" asChild>
									<Link to="/use-cases/diabetic-feet">View Case Study</Link>
								</ui.Button>
							</ui.CardFooter>
						</ui.Card>
					</AnimateInView>
				</div>
			</Section>
			<Section
				title="Here's how working with us works"
				description="You talk to us about your the challenges you're facing and the ideas you have. We'll then build a custom solution for you."
			>
				<div className="flex flex-col gap-6 mx-auto max-w-[1200px]">
					<div className="flex flex-col justify-center items-center gap-2">
						{steps.map((step, index) => (
							<AnimateInView key={step.step}>
								{index !== 0 && (
									<ArrowBigDown
										className={`mx-auto mb-2 flex w-8 h-8 text-${step.color}-400`}
									/>
								)}
								<ui.Card className="bg-white mx-auto w-full lg:w-[85%]">
									<ui.CardHeader>
										<div className="flex justify-between items-center mb-2">
											<span
												className={`font-semibold text-${step.color}-500 text-sm`}
											>
												Step {step.step}
											</span>
										</div>
										<div className="flex items-center gap-2">
											<step.icon className={`text-${step.color}-500`} />
											<ui.CardTitle>{step.title}</ui.CardTitle>
										</div>
										<ui.CardDescription>{step.description}</ui.CardDescription>
									</ui.CardHeader>
									<ui.CardContent className="overflow-auto">
										<div className="flex flex-wrap items-center gap-2">
											<span className="text-slate-600 text-sm">e.g.</span>
											{step.badges.map((badge, index) => (
												<Fragment
													key={typeof badge === "string" ? badge : badge.text}
												>
													{typeof badge === "string" ? (
														<span className="text-slate-600 text-sm">
															{badge}
															{index < step.badges.length - 2 && (
																<span className="text-slate-600">,</span>
															)}
															{index === step.badges.length - 2 && (
																<span className="text-slate-600"> or </span>
															)}
														</span>
													) : (
														<span className="flex items-center gap-1.5 text-slate-600 text-sm">
															<badge.icon className="w-4 h-4 text-slate-600" />
															{badge.text}
															{index < step.badges.length - 2 && (
																<span className="text-slate-600">,</span>
															)}
															{index === step.badges.length - 2 && (
																<span className="text-slate-600"> or </span>
															)}
														</span>
													)}
												</Fragment>
											))}
										</div>
									</ui.CardContent>
								</ui.Card>
							</AnimateInView>
						))}
					</div>
				</div>
			</Section>
		</>
	);
};

export const Route = createFileRoute("/_website/custom copy")({
	component: Custom,
});

export default Custom;
