import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import AuthWrap from "@/features/auth/components/AuthWrap";
import { Link, createFileRoute } from "@tanstack/react-router";
import { Call } from "../../components/ui/call";
import { useTryDemoMutation } from "../../features/auth/api/tryDemo";

const Demo = () => {
	const { mutate: tryDemo } = useTryDemoMutation();
	return (
		<AuthWrap>
			<Card className="mx-auto w-full max-w-2xl">
				<CardHeader>
					<CardTitle>Try Demo</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="space-y-4">
						<p className="text-lg text-slate-700">
							Try Patient Watch with example injection diary data:
						</p>
						<Call variant="info">
							<p>
								This is an example of our injection diary system. We also
								provide custom diary solutions for other medical tracking needs.
							</p>
							<div className="flex gap-2 pt-2">
								<Button asChild variant="secondary" size="sm">
									<Link to="/contact">Build your own</Link>
								</Button>
								<Button asChild variant="outline" size="sm">
									<Link to="/custom">Learn More</Link>
								</Button>
							</div>
						</Call>
						<ul className="space-y-2 pl-5 text-md text-slate-600 list-disc">
							<li>See how injection diaries are tracked and monitored</li>
							<li>
								See how you can quickly{" "}
								<strong className="text-slate-800">Take Action</strong> on
								patient records
							</li>
							<li>Print a diary to see the PDF record generated</li>
						</ul>

						<Button onClick={tryDemo} size="lg" className="mt-6">
							Try it out
						</Button>
					</div>
				</CardContent>
			</Card>
		</AuthWrap>
	);
};

export const Route = createFileRoute("/_auth/try-demo")({
	component: Demo,
});
