import { components as c } from "@/components";
import { createRedirectUrl } from "@/features/auth/utils/createRedirectUrl";
import { EmailZ, RoleZ } from "@/types/zod/auth";
import { FirstNameZ, LastNameZ, TitleZ } from "@/types/zod/profiles";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import GroupedFields from "../../../components/form/GroupedFields";
import { useAuth } from "../../../utils/auth";
import { useProfileByIdQuery } from "../../profile/api/selectProfile";
import { useInviteMutation } from "../api/useInviteMutation";
import SelectRole from "./SelectRole";

export const InviteSchemaZ = z.object({
	role: RoleZ,
	title: TitleZ,
	first_name: FirstNameZ,
	last_name: LastNameZ,
	email: EmailZ,
	invited_by: z.string().uuid(),
	invited_by_formal_name: z.string(),
	organisation: z.string(),
	redirect_url: z.string().url(),
});

export type InviteSchemaT = z.infer<typeof InviteSchemaZ>;

interface Props {
	role?: string;
	onSuccess?: () => void;
	submitButtonText?: React.ReactNode;
}

const InviteForm = ({ role, submitButtonText, onSuccess }: Props) => {
	const auth = useAuth();
	const { data: profile } = useProfileByIdQuery(auth.id);
	const form = useForm<InviteSchemaT>({
		resolver: zodResolver(InviteSchemaZ),
		defaultValues: {
			invited_by: auth.id,
			invited_by_formal_name: profile?.formal_name ?? "your Care Team",
			role: role?.toUpperCase() ?? "",
			redirect_url: createRedirectUrl("/confirm-account"),
		},
	});

	const invite = useInviteMutation({ onSuccess: onSuccess });

	const onSubmit: SubmitHandler<InviteSchemaT> = async (formData) => {
		const { organisation_id, organisation_name } = JSON.parse(
			formData.organisation,
		);
		invite.mutate({
			...formData,
			organisation_id: organisation_id,
			organisation_name: organisation_name,
		});
	};

	return (
		<c.ui.Form {...form}>
			<form
				onSubmit={form.handleSubmit(onSubmit)}
				className="space-y-4 text-lg"
			>
				{!role && <SelectRole />}
				<c.fc.SelectFromMyOrganisations />
				<c.fc.Email name="email" />
				<GroupedFields>
					<c.fc.SelectTitle />
					<c.fc.FirstName name="first_name" />
				</GroupedFields>
				<c.fc.LastName name="last_name" />

				<br />
				<c.ui.Button
					className=""
					data-cy="submit"
					size="lg"
					type="submit"
					disabled={invite.isPending}
				>
					{submitButtonText ?? "Send Invite"}
				</c.ui.Button>
			</form>
		</c.ui.Form>
	);
};

export default InviteForm;
