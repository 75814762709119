import { Button } from "@/components/ui/button";
import { Call } from "@/components/ui/call";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { useDemo } from "@/hooks/useDemo";
import { useNavigate } from "@tanstack/react-router";

interface StartDemoDialogProps {
	isOpen: boolean;
	onOpenChange: (open: boolean) => void;
}

export function StartDemoDialog({
	isOpen,
	onOpenChange,
}: StartDemoDialogProps) {
	const navigate = useNavigate();
	const isDemoEnvironment = useDemo();

	// Don't render anything if not in demo environment
	if (!isDemoEnvironment) {
		return null;
	}

	const handleClose = () => {
		onOpenChange(false);
		navigate({ to: "/profile" });
	};

	return (
		<Dialog open={isOpen} onOpenChange={onOpenChange}>
			<DialogContent onClose={handleClose}>
				<DialogHeader>
					<DialogTitle>Welcome to Patient Watch Demo</DialogTitle>
					<DialogDescription>
						You're now logged in with example injection diary data
					</DialogDescription>
				</DialogHeader>

				<div className="space-y-4">
					<Call variant="info">
						<p>
							This is an example of our injection diary system. We also provide
							custom diary solutions for other medical tracking needs.
						</p>
					</Call>

					<ul className="space-y-2 pl-5 text-md text-slate-600 list-disc">
						<li>See how injection diaries are tracked and monitored</li>
						<li>
							See how you can quickly{" "}
							<strong className="text-slate-800">Take Action</strong> on patient
							records
						</li>
						<li>Print a diary to see the PDF record generated</li>
					</ul>

					<Button onClick={handleClose} className="w-full">
						Go to Profile
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
}
