import { Button } from "@/components/ui/button";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
} from "@/components/ui/command";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import * as changeCase from "change-case";
import { CheckIcon, ChevronsUpDown, Lock, Plus } from "lucide-react";
import React from "react";
import { useFormContext } from "react-hook-form";
import AddOption from "../input/AddOption";
import { ScrollArea } from "../ui/scroll-area";
import { Separator } from "../ui/separator";

export type PatientOptionT = {
	label: string;
	value: string;
};

interface Props {
	label?: string;
	name: string;
	description?: string;
	options: PatientOptionT[];
	disabled?: boolean;
	autoFocus?: boolean;
	clearable?: boolean;
	addOptionButton?: React.ReactNode;
}

function ComboBox({
	options,
	autoFocus,
	disabled,
	label,
	name,
	description,
	clearable,
	addOptionButton,
}: Props) {
	const form = useFormContext();
	const [open, setOpen] = React.useState(false);
	const [search, setSearch] = React.useState("");

	return (
		<FormField
			control={form.control}
			name={name}
			render={({ field }) => (
				<FormItem className="w-full">
					{label && <FormLabel>{label}</FormLabel>}
					{description && <FormDescription>{description}</FormDescription>}
					<Popover open={open} onOpenChange={setOpen}>
						<PopoverTrigger
							data-testid={`${changeCase.kebabCase(name)}-trigger`}
							autoFocus={autoFocus}
							disabled={disabled}
							asChild
						>
							<FormControl>
								<Button
									variant="outline"
									role="combobox"
									size="sm"
									className={cn(
										"h-12 w-full min-w-[250px] justify-between rounded-md bg-white bg-opacity-100 px-3 text-sm hover:bg-slate-100",
										!field.value && "text-slate-500",
									)}
								>
									{field.value
										? options.find((o) => o.value === field.value)?.label
										: "Select"}
									{disabled ? (
										<Lock className="opacity-50 ml-2 w-4 h-4 shrink-0" />
									) : (
										<ChevronsUpDown className="opacity-50 ml-2 w-4 h-4 shrink-0" />
									)}
								</Button>
							</FormControl>
						</PopoverTrigger>
						<PopoverContent className="p-0 w-full">
							<Command>
								<CommandInput
									placeholder="Search..."
									className="border-none focus:ring-0 focus-visible:ring-0 focus-visible:ring-offset-0 h-9"
									value={search}
									onValueChange={setSearch}
								/>
								<CommandEmpty>No options found.</CommandEmpty>
								<CommandGroup>
									<ScrollArea className="h-52">
										{options.map((o) => (
											<CommandItem
												data-testid={`${changeCase.kebabCase(
													o.label ?? "",
												)}-option`}
												role="option"
												value={o.label}
												key={o.value}
												onSelect={() => {
													form.setValue(name, o.value);
													setOpen(false);
												}}
											>
												{o.label}
												<CheckIcon
													className={cn(
														"ml-auto h-4 w-4",
														o.value === field.value
															? "opacity-100"
															: "opacity-0",
													)}
												/>
											</CommandItem>
										))}
									</ScrollArea>
								</CommandGroup>
							</Command>
						</PopoverContent>
					</Popover>

					<FormMessage />
					<div className="flex justify-start items-center gap-2 pt-2">
						{addOptionButton && addOptionButton}

						{clearable && (
							<Button
								variant="outline"
								size="sm"
								type="button"
								onClick={() => {
									form.setValue(name, null);
								}}
							>
								Clear
							</Button>
						)}
					</div>
				</FormItem>
			)}
		/>
	);
}

export default ComboBox;
