import * as SliderPrimitive from "@radix-ui/react-slider";
import * as React from "react";

import { cn } from "@/lib/utils";

const Slider = React.forwardRef<
	React.ElementRef<typeof SliderPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
		thumb?: React.ReactNode;
		thumbClassName?: string;
	}
>(({ className, thumb, thumbClassName, ...props }, ref) => (
	<SliderPrimitive.Root
		ref={ref}
		className={cn(
			"relative flex w-full touch-none select-none items-center",
			className,
		)}
		{...props}
	>
		<SliderPrimitive.Track className="relative bg-indigo-100 dark:bg-indigo-800 rounded-full w-full h-4 overflow-hidden grow">
			<SliderPrimitive.Range className="absolute bg-indigo-800 dark:bg-indigo-50 h-full" />
		</SliderPrimitive.Track>
		<SliderPrimitive.Thumb
			className={cn(
				"block h-5 w-5 rounded-full border-2 border-indigo-900 bg-white ring-offset-white dark:ring-offset-slate-900 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:border-indigo-50 dark:bg-indigo-950 dark:ring-offset-indigo-950 dark:focus-visible:ring-indigo-300",
				thumbClassName,
			)}
		>
			{thumb}
		</SliderPrimitive.Thumb>
	</SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
