import { createFileRoute } from "@tanstack/react-router";
import Analytics from "../../features/analytics/Analytics";
import { selectEntriesQueryOptions } from "../../features/analytics/api/selectEventEntries";

const AnalyticsPage = () => {
	const { eventEntries } = Route.useLoaderData();
	return <Analytics eventEntries={eventEntries} />;
};

export const Route = createFileRoute("/_app/analytics")({
	component: AnalyticsPage,
	loader: async ({ context }) => {
		const eventEntries = await context.queryClient.ensureQueryData(
			selectEntriesQueryOptions(),
		);
		return { eventEntries };
	},
});
