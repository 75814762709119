import { supabaseClient } from "@/lib/supabase";

import type { Event } from "@/types/database/events";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { useRefetchDashboardData } from "../../dashboard/features/overview/table/hooks/useRefetchDashboardData";

import { useInsertTrackerEvent } from "./insertTrackerEvent";

export async function updateEvent(event: Event["Update"]) {
	const { data, error } = await supabaseClient
		.from("events")
		.update(event)
		.eq("event_id", event.event_id)
		.select("*")
		.single();
	if (error) throw error;
	return data;
}

export const useUpdateEvent = (trackerId?: string) => {
	const insertTrackerEvent = useInsertTrackerEvent();
	const refetchDashboardData = useRefetchDashboardData();

	return useMutation({
		mutationKey: ["update-event"],
		mutationFn: (event: Event["Update"]) => updateEvent(event),
		onError: () => {
			toast.error("Error updating event");
		},
		onSuccess: (data, mutation) => {
			toast.success("Event updated");
			if (trackerId) {
				insertTrackerEvent.mutate({
					tracker_id: trackerId,
					event_id: data.event_id,
				});
			}
			mutation?.onSuccess && mutation.onSuccess();
		},
		onSettled: () => {
			refetchDashboardData();
		},
	});
};
