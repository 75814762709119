import { supabaseClient } from "@/lib/supabase";
import { queryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

export async function selectEntries() {
	const { data, error } = await supabaseClient
		.from("event_entries_view")
		.select("*");
	if (error) throw error;
	return data;
}

export const selectEntriesQueryOptions = () =>
	queryOptions({
		queryKey: ["event-entries"],
		queryFn: async () => selectEntries(),
	});

export function useSelectEntries() {
	const options = selectEntriesQueryOptions();
	return useQuery(options);
}
