import OrganisationsComboBox from "@/features/organisations/components/OrganisationsComboBox";
import SelectFromMyOrganisationIds from "@/features/organisations/components/SelectFromMyOrganisationIds";
import SelectFromMyOrganisations from "@/features/organisations/components/SelectFromMyOrganisations";
import ComboBox from "./ComboBox";
import DateInput from "./Date";
import DateTime from "./DateTime";
import DateTimeInput from "./DateTime";
import Email from "./Email";
import Errors from "./Errors";
import FirstName from "./FirstName";
import Input from "./Input";
import LastName from "./LastName";
import MultiSelect from "./MultiSelect";
import MultipleChoiceCards from "./MultipleChoiceCards";
import NumberInput from "./Number";
import PIFU from "./PIFU";
import Password from "./Password";
import Phone from "./Phone";
import Rating from "./Rating";
import Recipient from "./Recipient";
import Recipients from "./Recipients";
import SaveButton from "./SaveButton";
import Select from "./Select";
import SelectRecipient from "./SelectRecipient";
import SelectTitle from "./SelectTitle";
import SingleChoice from "./SingleChoice";
import Star from "./Star";
import Switch from "./Switch";

import Text from "./Text";
import Textarea from "./Textarea";
import Time from "./Time";
import ProfileCombobox from "./UserCombobox";
import YesNo from "./YesNo";

export const formComponents = {
	Date: DateInput,
	DateTimeInput,
	MultipleChoiceCards,
	Input,
	ComboBox,
	Errors,
	DateTime,
	MultiSelect,
	Email,
	OrganisationsComboBox,
	SelectFromMyOrganisationIds,
	SelectFromMyOrganisations,
	Star,
	Rating,
	FirstName,
	LastName,
	UserCombobox: ProfileCombobox,
	YesNo,
	Password,
	Text,
	PIFU,
	Phone,
	Recipient,
	Recipients,
	SaveButton,
	Select,
	SelectRecipient,
	SelectTitle,
	Switch,
	Time,
	SingleChoice,
	Textarea,
	Number: NumberInput,
};
