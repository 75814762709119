import { formComponents as fc } from "@/components/form";
import { ui } from "@/components/ui";
import { useUpdateEvent } from "@/features/events/api/updateEvent";
import { zodResolver } from "@hookform/resolvers/zod";
import { type SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

interface Props {
	organisationId: string;
	eventId: string;
	event?: EventSchemaT;
	onSuccess?: () => void;
}

const EventSchemaZ = z.object({
	event_id: z.string().nonempty("Event ID is required"),
	event_name: z.string().nonempty("Event name is required"),
	event_time: z.string().nonempty("Event time is required"), // Adjust type if needed
});

type EventSchemaT = z.infer<typeof EventSchemaZ>;

const EditEventForm = ({
	eventId,
	event,
	organisationId,
	onSuccess,
}: Props) => {
	const updateEventMutation = useUpdateEvent();

	const form = useForm<EventSchemaT>({
		resolver: zodResolver(EventSchemaZ),
		defaultValues: {
			event_id: eventId,
			event_name: event?.event_name ?? "",
			event_time: event?.event_time ?? "",
		},
	});

	const onSubmit: SubmitHandler<EventSchemaT> = async (formData) => {
		updateEventMutation.mutate(formData, { onSuccess });
	};

	return (
		<ui.Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="form">
				<fc.Input
					name="event_name"
					label="Event Name"
					description="Name of the event"
				/>
				<fc.DateTime
					name="event_time"
					label="Event Time"
					description="Time of the event"
				/>
				<fc.SaveButton />
			</form>
		</ui.Form>
	);
};

export default EditEventForm;
