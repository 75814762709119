import { supabaseClient } from "@/lib/supabase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { createProfileQueryKey } from "../../profile/api/selectProfile";

const tryDemo = async () => {
	const { data, error } = await supabaseClient.functions.invoke(
		"public-try-demo",
		{
			body: {},
		},
	);
	if (error) throw error;
	return data;
};

export const useTryDemoMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationKey: ["try-demo"],
		mutationFn: () => tryDemo(),
		onError: () => {
			toast.error("Error creating demo account");
		},
		onSuccess: (data) => {
			// Redirect to the magic link
			window.location.href = data.properties.action_link;
		},
		onSettled: (data) => {
			queryClient.refetchQueries({ queryKey: ["user"] });
			queryClient.refetchQueries({
				queryKey: createProfileQueryKey(data.user.id),
			});
		},
	});
};
