import type { RoleT } from "@/types/zod/auth";
import type { RouteT } from "@/types/zod/routes";
import {
	BarChart,
	ClipboardEdit,
	ClipboardList,
	Eye,
	Hammer,
	LineChart,
	ShieldQuestion,
	Table2,
	User2,
	UserPlus2,
} from "lucide-react";
import type { ReactNode } from "react";
import type { ButtonVariant } from "../../../components/ui/button";

export interface PageT {
	name: string;
	href: RouteT;
	description?: string;
	icon?: ReactNode;
	external?: boolean;
	draft?: boolean;
	iconOnly?: boolean;
	variant?: ButtonVariant;
	roles: RoleT[];

	super_user_only?: boolean;
}

export const navigationContent: PageT[] = [
	{
		name: "Profile",
		href: "/profile",
		description: "Update the information required for Patient Watch to work.",
		icon: <User2 strokeWidth={1.5} size={28} />,
		// icon: <i.Patient />,
		variant: "ghost",
		roles: ["patient", "clinician", "secretary", "admin"],
	},

	{
		name: "Admin",
		href: "/admin",
		description: "See what users are doing on Patient Watch.",
		icon: <Eye strokeWidth={1.5} size={28} />,
		roles: ["admin"],
		variant: "ghost",
	},
	{
		name: "Builder",
		href: "/builder",
		description: "Control how users interact with Patient Watch",
		icon: <Hammer strokeWidth={1.5} size={28} />,
		roles: ["admin"],
		variant: "ghost",
	},

	{
		name: "Builder",
		href: "/builder",
		description: "Create diaries to fit your needs.",
		icon: <Hammer strokeWidth={1.5} size={28} />,
		roles: [],
		super_user_only: true,
		variant: "ghost",
	},
	{
		name: "Invite",
		href: "/invite",
		variant: "ghost",
		description: "Invite a clinician, patient or secretary to Patient Watch.",
		icon: <UserPlus2 strokeWidth={1.5} size={28} />,
		roles: ["admin"],
	},
	{
		name: "Statistics",
		href: "/statistics" as RouteT,
		description: "Manage your patients all from one place.",
		icon: <LineChart strokeWidth={1.5} size={28} />,
		roles: ["admin"],
		variant: "ghost",
		super_user_only: true,
	},
	{
		name: "Dashboard",
		href: "/dashboard?tab=active" as RouteT,
		description: "Manage your patients all from one place.",
		icon: <Table2 strokeWidth={1.5} size={28} />,
		roles: ["clinician", "secretary", "admin"],
		variant: "ghost",
	},
	{
		name: "Diaries",
		href: "/diaries",
		description: "Create diaries for patients to track their symptoms.",
		icon: <ClipboardList strokeWidth={1.5} size={28} />,
		variant: "default",
		roles: ["patient"],
	},
	{
		name: "New Diary",
		href: "/new-diary",
		description: "Create diaries for patients to track their symptoms.",
		icon: <ClipboardEdit strokeWidth={1.5} size={28} />,
		roles: ["clinician", "secretary", "admin"],
		variant: "default",
	},
	{
		name: "Analytics",
		href: "/analytics",
		description: "Analyse the data from your patients",
		icon: <BarChart strokeWidth={1.5} size={28} />,
		roles: ["admin"],
		variant: "ghost",
	},
	// {
	//     name: 'Updates',
	//     href: '/updates',
	//     description: 'An automated pain diary for therapeutic injections.',
	//     icon: <Bell strokeWidth={1.5} size={28} />,
	//     roles: ['patient', 'clinician'],
	//     iconOnly: true,
	//     variant: 'ghost',
	// },
];
