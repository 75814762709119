import { components as c } from "@/components";
import InvitePatientForm from "@/features/new-diary/features/invite-patient/InvitePatientForm";
import { Route as SelectRoute } from "@/routes/_app/new-diary/patient/select";
import { useAuth } from "@/utils/auth";
import { Link, createFileRoute } from "@tanstack/react-router";

const Invite = () => {
	const search = Route.useSearch();

	return (
		<section className="md-width">
			<c.cc.Title words="New Diary" />
			<InvitePatientForm
				organisation={search.organisation}
				diary_route={search.diary_route}
			/>
			<c.cc.Or />
			<c.ui.Button type="button" tabIndex={0} size="lg" variant="secondary">
				<Link
					from={Route.to}
					to={SelectRoute.to}
					search={search}
					className="w-full"
				>
					Select Existing Patient
				</Link>
			</c.ui.Button>
		</section>
	);
};

import { NewDiaryRouteSearchZ } from "@/routes/_app/new-diary/patient/select";

export const Route = createFileRoute("/_app/new-diary/patient/invite")({
	component: Invite,
	errorComponent: () => <p>New Diary Invite Error</p>,
	validateSearch: (search) => NewDiaryRouteSearchZ.parse(search),
});
