//

import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@/components/ui/card";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { BarList } from "@tremor/react";
import _ from "lodash";
import { useState } from "react";
import { Button } from "../../components/ui/button";
import type { EventEntryT } from "../../types/database/events";
const valueFormatter = (number) =>
	`${Intl.NumberFormat("us").format(number).toString()}`;

export interface Props {
	eventEntries: EventEntryT[];
	type: "site" | "substance";
}

export default function MostCommonInjections({ eventEntries, type }: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");

	const field = type === "site" ? "injection_site" : "injection_substance";
	const title =
		type === "site" ? "Injection Locations" : "Injection Substances";

	const data = Object.entries(_.countBy(eventEntries, field))
		.map(([name, value]) => ({
			name,
			value,
		}))
		.filter((dataPoint) => dataPoint.name !== "null");

	return (
		<Card className="w-full">
			<CardHeader>
				<CardTitle>{title}</CardTitle>
				<CardDescription>
					{data.length} unique {type === "site" ? "locations" : "substances"}
				</CardDescription>
			</CardHeader>
			<CardContent className="relative">
				<BarList
					data={data.slice(0, 6)}
					valueFormatter={valueFormatter}
					className="mt-4"
					color={`${type === "site" ? "indigo" : "sky"}-200`}
				/>
				{data.length > 6 && (
					<div className="bottom-0 left-0 absolute inset-x-0 flex justify-center bg-gradient-to-t from-white dark:from-black to-transparent py-7 rounded-lg">
						<Button
							size="sm"
							variant="outline"
							className="!bg-white hover:!bg-slate-50"
							type="button"
							onClick={() => setIsOpen(true)}
						>
							Show more
						</Button>
					</div>
				)}
				<Dialog open={isOpen} onOpenChange={setIsOpen}>
					<DialogContent className="max-w-xl">
						<DialogHeader>
							<DialogTitle>All Injection Locations</DialogTitle>
						</DialogHeader>
						<div className="space-y-4">
							<Input
								type="search"
								placeholder="Search locations..."
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								className="w-full"
							/>
							<div className="flex justify-between items-center">
								<p className="font-medium">Injections</p>
								<p className="font-medium text-slate-500 text-sm uppercase">
									Count
								</p>
							</div>
							<div className="h-96 overflow-y-auto">
								{data.length > 0 ? (
									<BarList
										data={data}
										valueFormatter={valueFormatter}
										color={`${type === "site" ? "indigo" : "sky"}-200`}
									/>
								) : (
									<p className="flex justify-center items-center h-full text-muted-foreground">
										No results.
									</p>
								)}
							</div>
						</div>
					</DialogContent>
				</Dialog>
			</CardContent>
		</Card>
	);
}
