import { ui } from "@/components/ui";
import AllCharts from "@/features/charts/components/AllCharts";
import { interfaceComponents as ic } from "@/features/dashboard/features/interface/components";
import DiaryTitle from "@/features/dashboard/features/interface/components/DiaryTitle";
import NoDiarySelected from "@/features/dashboard/features/interface/components/NoDiarySelected";
import { useDiaryByIdQuery } from "@/features/diary/api/getDiaryById";
import History from "@/features/diary/history/History";
import { collateDiaryHistory } from "@/features/diary/services/collateDiaryHistory";
import { hooks as h } from "@/hooks";
import { createFileRoute } from "@tanstack/react-router";

const DashboardDiaryIdPage = () => {
	const { diaryId } = Route.useParams();

	const { data: diary } = useDiaryByIdQuery(diaryId);

	h.useNav(
		diaryId
			? [
					{ label: "Dashboard", route: { to: "/dashboard" } },
					{
						label: diary?.diary_name ?? "Diary",
						route: {
							to: "/dashboard/diary/$diaryId",
							params: { diaryId: diaryId },
						},
					},
				]
			: [{ label: "Dashboard", route: { to: "/dashboard" } }],
	);

	if (!diary) return <NoDiarySelected />;

	return (
		<section className="flex flex-col h-full">
			<ic.InterfaceButtons diary={diary} />
			<ui.ScrollArea>
				<div className="flex flex-col gap-2 mt-3 h-full">
					<DiaryTitle
						patientName={diary.patient_info?.patient_name as string}
						isEditing={false}
						diaryId={diaryId}
					/>
					<ic.StateCard diary={diary} />
					<ic.AiSummary diary={diary} />
					{diary.trackers_view?.length > 0 && <AllCharts diary={diary} />}
					<History
						size="sm"
						editable={false}
						diaryId={diaryId}
						diaryHistory={collateDiaryHistory(diary)}
					/>
				</div>

				<ui.ScrollBar />
			</ui.ScrollArea>
		</section>
	);
};

export const Route = createFileRoute("/_app/dashboard/diary/$diaryId/")({
	component: DashboardDiaryIdPage,
});
