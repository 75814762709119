import { ui } from "@/components/ui";
import React from "react";
import { buttonVariants } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import InviteForm from "./InviteForm";

interface Props {
	words?: string;
	className?: string;
}

const InviteDialog = ({ words, className }: Props) => {
	const [open, setOpen] = React.useState<boolean>(false);
	return (
		<ui.Dialog open={open}>
			<ui.DialogTrigger
				className={cn(
					buttonVariants({ size: "sm", variant: "secondary" }),
					className,
				)}
				onClick={() => setOpen(true)}
			>
				{words ?? "Invite New User"}
			</ui.DialogTrigger>
			<ui.DialogContent onClose={() => setOpen(false)}>
				<ui.DialogHeader>
					<ui.DialogTitle>Invite New User</ui.DialogTitle>
				</ui.DialogHeader>
				<InviteForm onSuccess={() => setOpen(false)} />
			</ui.DialogContent>
		</ui.Dialog>
	);
};

InviteDialog.propTypes = {};

export default InviteDialog;
