import { supabaseClient } from "@/lib/supabase";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { toast } from "sonner";

const smartAuth = async (formData: { email: string }) => {
	const { data, error } = await supabaseClient.functions.invoke(
		"public-smart-auth",
		{ body: formData },
	);
	if (error) throw error;
	if (data) return data;
};

const useSmartAuthMutation = () => {
	const navigate = useNavigate();
	return useMutation({
		mutationKey: ["login"],
		mutationFn: async (formData: { email: string }) => {
			return smartAuth(formData);
		},
		onError: (error) => {
			console.error(error);
			toast.error(error.message);
		},
		onSuccess: (data) => {
			if (data.auth_method === "login") {
				toast.info("Account found");
				navigate({ to: "/login", search: { email: data.email } });
			} else if (data.auth_method === "confirm-account") {
				toast.info("Invitation found");
				navigate({
					to: "/confirm-account",
					search: { email: data.email, invited_by_name: data.invited_by_name },
				});
			} else if (data.auth_method === "signup") {
				toast.info("New user");
				navigate({ to: "/new-user" });
			} else {
				console.error(data);
				toast("Auth method error");
			}
		},
	});
};

export default useSmartAuthMutation;
