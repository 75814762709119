import { supabaseClient } from "@/lib/supabase";
import type { StatefulDiaryT } from "@/types/database/diaries";
import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";

export async function getDiaryById(diaryId: string) {
	const { data, error } = await supabaseClient.functions.invoke(
		"public-get-diaries",
		{
			body: { diary_id: diaryId },
		},
	);

	if (error) throw error;

	return data[0] as StatefulDiaryT;
}

export const createGetDiaryKey = (diaryId: string) => [
	"diary",
	{ diary_id: diaryId },
];

export const diaryByIdQueryOptions = (diaryId: string) =>
	queryOptions({
		queryKey: createGetDiaryKey(diaryId),
		queryFn: () => getDiaryById(diaryId),
	});

export const useDiaryByIdQuery = (diaryId: string) => {
	return useSuspenseQuery(diaryByIdQueryOptions(diaryId));
};
