import { supabaseClient } from "@/lib/supabase";
import type { DashboardSearchT } from "@/routes/_app/dashboard";
import type { DiaryViewT } from "@/types/database/diaries";
import { type Auth, useAuth } from "@/utils/auth";
import { queryOptions, useQuery } from "@tanstack/react-query";

export const prepDiariesData = (settings: DashboardSearchT, auth?: Auth) => {
	if (!settings?.tab) throw new Error("Tab is required");

	if (!auth) throw new Error("Not logged in");

	return {
		...settings,
		diary_access: settings?.diary_access ?? undefined,
		user_id: settings?.diaries === "mine" ? auth.id : undefined,
	};
};

export async function selectDashboardDiaries(
	settings: DashboardSearchT,
	auth: Auth,
): Promise<DiaryViewT[]> {
	let query = supabaseClient.from("diaries_view").select("*");

	const { tab, user_id, diary_access, organisations } = prepDiariesData(
		settings,
		auth,
	);

	if (tab) {
		query = query.eq("tab", tab.toLowerCase());
	}

	if (user_id) {
		query = query.contains("direct_access", [user_id]);
	}

	if (diary_access && diary_access?.length > 0) {
		query = query.overlaps("direct_access", diary_access);
	}

	if (organisations && organisations?.length > 0) {
		query = query.in("organisation_id", organisations);
	}

	const { data: diaries, error } = await query;

	if (error) throw error;

	return diaries as DiaryViewT[];
}

export const createDashboardDiariesQueryKey = (search?: DashboardSearchT) => [
	"dashboard",
	"diaries",
	{ ...search },
];

export const dashboardDiaryQueryOptions = (
	settings: DashboardSearchT,
	auth: Auth,
) => {
	return queryOptions({
		queryKey: createDashboardDiariesQueryKey(settings),
		queryFn: () => selectDashboardDiaries(settings, auth),
		staleTime: 0, // Force refetch
	});
};
export const useDashboardDiariesQuery = (settings: DashboardSearchT) => {
	const auth = useAuth();
	return useQuery(dashboardDiaryQueryOptions(settings, auth));
};
