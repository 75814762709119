import { commonComponents as cc } from "@/components/common";
import { ui } from "@/components/ui";
import {
	selectFormByIdQueryOptions,
	useSelectFormById,
} from "@/features/forms/api/selectFormById";
import { useFormStore } from "@/features/inputs/hooks/useFormStore";
import FormPage from "@/features/pages/components/FormPage";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

const Preview = () => {
	const { formId, pageIndex } = Route.useParams();
	const { data: form } = useSelectFormById(formId);

	const formStore = useFormStore();

	useEffect(() => {
		formStore.setPages(form?.pages ?? []);
	}, []);

	return (
		<section className="flex flex-col gap-4 px-2 w-full">
			<cc.PhonePreview>
				<div className="flex justify-between items-center">
					<h4 className="pb-2 text-slate-800 text-xl">{form?.form_name}</h4>
					<ui.Button
						className="cursor-not-allowed pointer-events-none"
						variant="link"
						size="sm"
					>
						Edit
					</ui.Button>
				</div>
				<FormPage
					pageIndex={Number(pageIndex)}
					pages={form?.pages}
					// page={form?.pages?.find((p) => p.page_index === pageIndex)}
					diaryName={form?.form_name}
					route={Route}
				/>
			</cc.PhonePreview>
		</section>
	);
};

export const Route = createFileRoute(
	"/_app/builder/form/$formId/page-input/$pageInputId/input/$inputId/page/$pageIndex/",
)({
	component: Preview,
	loader: async ({ context, params }) => {
		return await context?.queryClient.ensureQueryData(
			selectFormByIdQueryOptions(params.formId),
		);
	},
});
