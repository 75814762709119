import { ui } from "@/components/ui";
import type { ExtendedInjectionViewT } from "@/types/database/events";
import EditEventForm from "../../events/components/EditEventForm";
import TrackerOverviewForm from "../../trackers/components/TrackerOverviewForm";
import InjectionDetailsForm from "./InjectionDetailsForm";
import InjectionForm from "./InjectionForm";

interface Props {
	diaryId: string;
	trackerId?: string;
	eventId: string;
	organisationId: string;
	injectionId: string;
	injection?: ExtendedInjectionViewT;
	event: Event;
}

const InjectionFormTabs = (props: Props) => {
	return (
		<ui.Tabs
			defaultValue="injection"
			className="flex flex-col justify-center items-center w-full"
		>
			<ui.TabsList className="mx-auto">
				{props.trackerId && (
					<ui.TabsTrigger className="px-4 py-2 text-md" value="admin">
						Admin
					</ui.TabsTrigger>
				)}
				<ui.TabsTrigger className="px-4 py-2 text-md" value="intervention">
					Intervention
				</ui.TabsTrigger>
				<ui.TabsTrigger className="px-4 py-2 text-md" value="injection">
					Injection
				</ui.TabsTrigger>
				<ui.TabsTrigger className="px-4 py-2 text-md" value="details">
					Details
				</ui.TabsTrigger>
			</ui.TabsList>
			{props.trackerId && (
				<ui.TabsContent className="w-full" value="admin">
					<TrackerOverviewForm
						trackerId={props.trackerId}
						diaryId={props.diaryId}
					/>
				</ui.TabsContent>
			)}
			<ui.TabsContent className="w-full" value="intervention">
				<EditEventForm
					eventId={props.eventId}
					organisationId={props.organisationId}
					event={props.event}
				/>
			</ui.TabsContent>
			<ui.TabsContent className="w-full" value="injection">
				<InjectionForm
					injection={props.injection}
					injectionId={props.injectionId}
					diaryId={props.diaryId}
					eventId={props.eventId}
					organisationId={props.organisationId}
				/>
			</ui.TabsContent>
			<ui.TabsContent className="w-full" value="details">
				<InjectionDetailsForm
					injection={props.injection}
					diaryId={props.diaryId}
					injectionId={props.injectionId}
					eventId={props.eventId}
				/>
			</ui.TabsContent>
		</ui.Tabs>
	);
};

export default InjectionFormTabs;
