import { ui } from "@/components/ui";
import type { SurgeryViewT } from "../../../../../types/database/events";
import TrackerOverviewForm from "../../../../trackers/components/TrackerOverviewForm";
import EditEventForm from "../../../components/EditEventForm";
import SurgeryCliniciansForm from "./SurgeryCliniciansForm";
import SurgeryForm from "./SurgeryForm";

interface Props {
	diaryId: string;
	trackerId?: string;
	eventId: string;
	organisationId: string;
	surgeryId: string;
	surgery?: SurgeryViewT;
}

const SurgeryFormTabs = (props: Props) => {
	return (
		<ui.Tabs
			defaultValue="surgery"
			className="flex flex-col justify-center items-center w-full"
		>
			<ui.TabsList className="mx-auto">
				<ui.TabsTrigger className="px-4 py-2 text-md" value="intervention">
					Intervention
				</ui.TabsTrigger>
				<ui.TabsTrigger className="px-4 py-2 text-md" value="surgery">
					Surgery
				</ui.TabsTrigger>
				<ui.TabsTrigger className="px-4 py-2 text-md" value="clinicians">
					Clinicians
				</ui.TabsTrigger>
			</ui.TabsList>
			{props.trackerId && (
				<ui.TabsContent className="w-full" value="admin">
					<TrackerOverviewForm
						trackerId={props.trackerId}
						diaryId={props.diaryId}
					/>
				</ui.TabsContent>
			)}
			<ui.TabsContent className="w-full" value="intervention">
				<EditEventForm
					eventId={props.eventId}
					organisationId={props.organisationId}
					event={props.event}
				/>
			</ui.TabsContent>
			<ui.TabsContent className="w-full" value="surgery">
				<SurgeryForm
					surgery={props.surgery}
					surgeryId={props.surgeryId}
					diaryId={props.diaryId}
					eventId={props.eventId}
				/>
			</ui.TabsContent>
			<ui.TabsContent className="w-full" value="clinicians">
				<SurgeryCliniciansForm
					surgery={props.surgery}
					surgeryId={props.surgeryId}
					diaryId={props.diaryId}
					eventId={props.eventId}
				/>
			</ui.TabsContent>
		</ui.Tabs>
	);
};

export default SurgeryFormTabs;
