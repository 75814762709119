import ErrorPage from "@/components/pages/ErrorPage";
import Navigation from "@/features/navigation/components/Navigation";
import Footer from "@/features/website/components/Footer";
import { useAuth } from "@/utils/auth";

import {
	Link,
	Outlet,
	ScrollRestoration,
	createFileRoute,
} from "@tanstack/react-router";
import { useEffect, useState } from "react";
import Banner from "../components/common/Banner";
import { StartDemoDialog } from "../features/demo/StartDemoDialog";
import { useDemo } from "../hooks/useDemo";
import { cn } from "../lib/utils";
export const Route = createFileRoute("/_website")({
	component: LayoutComponent,
	errorComponent: ({ error, reset }) => (
		<ErrorPage title="Website Error" error={error} reset={reset} />
	),
});

function LayoutComponent() {
	const [showBanner, setShowBanner] = useState(false);
	const auth = useAuth();

	useEffect(() => {
		if (auth.isAuthenticated) {
			setShowBanner(true);
		}
	}, [auth.isAuthenticated]);

	return (
		<main className="pb-2 min-h-screen">
			<ScrollRestoration />

			<Banner
				show={showBanner}
				setShow={setShowBanner}
				className={cn("bg-violet-300", auth.isAuthenticated ? "h-fit" : "h-0")}
			>
				<p
					className={cn(
						auth.isAuthenticated ? "flex flex-row gap-2 text-md" : "hidden",
					)}
				>
					You are logged in.
					<Link
						className="text-indigo-600 underline underline-offset-2"
						to={auth.roles.isPatient ? "/diaries" : "/dashboard"}
					>
						Go to {auth.roles.isPatient ? "Diaries" : "Dashboard"}
					</Link>
				</p>
			</Banner>
			<StartDemoDialog isOpen={showBanner} onOpenChange={setShowBanner} />
			<Navigation app={false} />
			<Outlet />
			<div className="px-8 py-8">
				<hr className="border-slate-300" />
			</div>
			<Footer auth={auth} />
		</main>
	);
}
