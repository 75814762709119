interface Props {
	className?: string;
}

const Lightbulb = ({ className }: Props) => {
	return (
		<svg
			className={className}
			title="lightbulb"
			fill="#000000"
			height="800px"
			width="800px"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 455.881 455.881"
			enableBackground="new 0 0 455.881 455.881"
		>
			<g>
				<path d="m321.218,257.555c15.516-20.46 23.717-44.881 23.717-70.624 0-31.563-12.379-61.151-34.857-83.314-22.474-22.158-52.261-34.124-83.828-33.668-30.591,0.433-59.412,12.708-81.154,34.564-21.741,21.855-33.867,50.741-34.145,81.335-0.238,26.228 8.011,51.088 23.855,71.894 25.721,33.776 39.887,75.149 39.887,116.498v45.244c0,20.069 16.327,36.396 36.396,36.396h33.854c20.069,0 36.396-16.328 36.396-36.396v-45.241c0.001-41.806 13.691-82.157 39.879-116.688zm-174.478-8.9c-13.808-18.132-20.997-39.803-20.79-62.67 0.499-54.935 45.588-100.26 100.512-101.037 27.553-0.37 53.493,10.035 73.084,29.352 19.597,19.321 30.389,45.116 30.389,72.632 0,22.442-7.147,43.729-20.669,61.56-27.593,36.385-42.45,78.833-43.058,122.93h-76.536c-0.627-43.669-15.817-87.161-42.932-122.767zm42.953,165.746v-6.072l76.647,8.781v2.374c0,1.25-0.13,2.468-0.336,3.659l-76.311-8.742zm0-21.17v-6.809h76.647v15.59l-76.647-8.781zm21.396,47.65c-8.057,0-15.082-4.48-18.731-11.077l64.567,7.397c-3.421,2.321-7.545,3.679-11.981,3.679h-33.855z" />
				<path d="m153.776,198.213c-0.585-3.925-0.864-7.957-0.827-11.983 0.038-4.142-3.289-7.53-7.431-7.568-4.114-0.036-7.53,3.289-7.568,7.431-0.044,4.81 0.289,9.632 0.99,14.333 0.555,3.722 3.755,6.395 7.409,6.395 0.368,0 0.741-0.027 1.116-0.083 4.096-0.612 6.922-4.428 6.311-8.525z" />
				<path d="m197.913,312.135c0.776,0 1.566-0.122 2.344-0.377 3.935-1.294 6.076-5.533 4.782-9.467-8.312-25.277-20.7-48.827-36.82-69.994-2.664-3.499-5.025-7.226-7.016-11.079-1.902-3.68-6.427-5.12-10.107-3.218-3.679,1.902-5.12,6.427-3.218,10.107 2.39,4.622 5.218,9.089 8.408,13.278 15.106,19.836 26.715,41.904 34.504,65.591 1.038,3.157 3.971,5.159 7.123,5.159z" />
				<path d="m306.746,212.613c-3.804-1.639-8.217,0.117-9.855,3.921-2.376,5.518-5.451,10.781-9.139,15.643-2.503,3.3-1.856,8.005 1.444,10.508 1.355,1.028 2.947,1.524 4.526,1.524 2.267,0 4.507-1.023 5.982-2.969 4.419-5.827 8.107-12.143 10.963-18.772 1.639-3.804-0.116-8.217-3.921-9.855z" />
				<path d="m227.941,111.938c41.352,0 74.994,33.643 74.994,74.995 0,2.351-0.108,4.72-0.321,7.041-0.378,4.125 2.658,7.775 6.783,8.154 0.233,0.021 0.464,0.032 0.694,0.032 3.833,0 7.103-2.923 7.46-6.815 0.254-2.775 0.384-5.605 0.384-8.412 0-49.623-40.371-89.995-89.994-89.995-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5z" />
				<path d="m227.941,57c4.142,0 7.5-3.358 7.5-7.5v-42c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v42c0,4.142 3.358,7.5 7.5,7.5z" />
				<path d="m152.065,71.82c1.39,2.407 3.91,3.752 6.502,3.752 1.272,0 2.562-0.324 3.743-1.006 3.587-2.071 4.817-6.658 2.746-10.245l-20.99-36.36c-2.072-3.588-6.658-4.817-10.245-2.746-3.587,2.071-4.817,6.658-2.746,10.245l20.99,36.36z" />
				<path d="m388.22,272.115l-36.36-20.99c-3.588-2.072-8.175-0.842-10.245,2.746-2.071,3.587-0.842,8.174 2.746,10.245l36.36,20.99c1.181,0.682 2.47,1.006 3.743,1.006 2.592,0 5.113-1.346 6.502-3.752 2.071-3.587 0.841-8.174-2.746-10.245z" />
				<path d="m67.661,104.366l36.36,20.99c1.181,0.682 2.47,1.006 3.743,1.006 2.592,0 5.113-1.346 6.502-3.752 2.071-3.587 0.842-8.174-2.746-10.245l-36.36-20.99c-3.587-2.072-8.174-0.842-10.245,2.746-2.071,3.587-0.842,8.173 2.746,10.245z" />
				<path d="m408.68,180.74h-42c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h42c4.142,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
				<path d="m96.7,188.24c0-4.142-3.358-7.5-7.5-7.5h-42c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5h42c4.143,0 7.5-3.358 7.5-7.5z" />
				<path d="m348.117,126.362c1.272,0 2.562-0.324 3.743-1.006l36.36-20.99c3.587-2.071 4.817-6.658 2.746-10.245-2.071-3.587-6.659-4.817-10.245-2.746l-36.36,20.99c-3.587,2.071-4.817,6.658-2.746,10.245 1.389,2.406 3.911,3.752 6.502,3.752z" />
				<path d="m104.021,251.125l-36.36,20.99c-3.587,2.071-4.817,6.658-2.746,10.245 1.389,2.406 3.91,3.752 6.502,3.752 1.272,0 2.562-0.324 3.743-1.006l36.36-20.99c3.587-2.071 4.817-6.658 2.746-10.245-2.07-3.588-6.66-4.817-10.245-2.746z" />
				<path d="m293.571,74.566c1.181,0.682 2.47,1.006 3.743,1.006 2.592,0 5.113-1.346 6.502-3.752l20.99-36.36c2.071-3.587 0.841-8.174-2.746-10.245-3.586-2.071-8.174-0.842-10.245,2.746l-20.99,36.36c-2.071,3.587-0.841,8.174 2.746,10.245z" />
			</g>
		</svg>
	);
};

export default Lightbulb;
