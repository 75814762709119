import { colors } from "@/assets/colors";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "@/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { generateOptions } from "@/features/charts/services/generateOptions";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import dayjs from "dayjs";
import { Line } from "react-chartjs-2";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "../../components/ui/card";
import type { AnalyticsBaseProps } from "./types";

// Register ChartJS components
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
);

const AverageScoreByLabel = ({ eventEntries }: AnalyticsBaseProps) => {
	// Calculate minimum count threshold (10% of total events, minimum of 3)
	const minEntriesThreshold = Math.max(Math.ceil(eventEntries.length * 0.1), 3);

	const entries = eventEntries.flatMap(
		(event) =>
			event.entries?.map((entry) => ({
				value:
					entry.entry_values?.find((value) => value.number_value !== null)
						?.number_value ?? 0,
				label: entry.timing_label,
				time:
					Math.round(
						dayjs().add(entry.timing_interval, entry.time_unit).valueOf() /
							1000,
					) * 1000,
			})) ?? [],
	);

	const averageEntries = Object.values(
		entries.length > 0
			? entries.reduce(
					(acc, entry) => {
						if (!acc[entry.label]) {
							acc[entry.label] = {
								label: entry.label,
								values: [],
								times: [],
							};
						}
						acc[entry.label].values.push(Math.min(entry.value, 10));
						acc[entry.label].times.push(entry.time);
						return acc;
					},
					{} as Record<
						string,
						{ label: string; values: number[]; times: number[] }
					>,
				)
			: {},
	).map((group) => ({
		label: group.label,
		value: Math.min(
			group.values.reduce((sum, val) => sum + val, 0) / group.values.length,
			10,
		),
		time: Math.max(...group.times),
		count: group.values.length,
	}));

	const chartData = averageEntries
		.filter((entry) => entry.count >= minEntriesThreshold)
		.sort((a, b) => a.time - b.time)
		.map((entry) => ({
			...entry,
			formattedTime: dayjs(entry.time).format("MMM D, HH:mm"),
		}));

	const data = {
		labels: chartData.map((entry) => entry.label),
		datasets: [
			{
				data: chartData.map((entry) => entry.value),
				borderColor: colors["sky-300"],
				backgroundColor: colors["slate-50"],
				borderWidth: 2,
				pointRadius: 4,
				pointHoverRadius: 5,
				tension: 0.3,
				fill: false,
			},
		],
	};

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			mode: "nearest" as const,
			intersect: true,
		},
		plugins: {
			tooltip: {
				padding: 10,
				displayColors: false,
				backgroundColor: "rgba(253,253,253,1)",
				titleColor: "black",
				bodyColor: "black",
				borderColor: "rgba(190,190,190,1)",
				borderWidth: 1,
				titleFont: { size: 14 },
				bodyFont: { size: 16 },
				callbacks: {
					title: (context: any) => {
						return chartData[context[0].dataIndex].label;
					},
					label: (context: any) => {
						const entry = chartData[context.dataIndex];
						return [
							`Average Score: ${entry.value.toFixed(1)}/10`,
							`Based on ${entry.count} entries`,
						];
					},
				},
			},
			legend: {
				display: false,
			},
		},
		scales: {
			x: {
				type: "category" as const,
				ticks: {
					color: colors["slate-600"],
					font: {
						size: 14,
						weight: 500,
					},
					maxRotation: 45,
					minRotation: 45,
				},
				grid: {
					color: colors["slate-200"],
				},
			},
			y: {
				min: 0,
				max: 10,
				ticks: {
					stepSize: 1,
					color: colors["slate-600"],
					font: {
						size: 14,
						weight: 500,
					},
				},
				grid: {
					color: colors["slate-200"],
				},
			},
		},
	};

	return (
		<Card className="bg-white">
			<Tabs defaultValue="chart" className="w-full">
				<CardHeader className="flex flex-row justify-between items-start px-4">
					<div>
						<CardTitle className="text-slate-900">
							Average Score by Label
						</CardTitle>
						<CardDescription>
							Showing labels with {minEntriesThreshold} or more entries
						</CardDescription>
					</div>
					<TabsList className="">
						<TabsTrigger value="chart">Chart</TabsTrigger>
						<TabsTrigger value="table">Table</TabsTrigger>
					</TabsList>
				</CardHeader>

				<CardContent>
					<TabsContent value="chart">
						<div className="mt-4 w-full h-[400px]">
							<Line options={chartOptions} data={data} />
						</div>
					</TabsContent>
					<TabsContent value="table">
						<div className="mt-4">
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Label</TableHead>
										<TableHead>Average Score</TableHead>
										<TableHead>Last Update</TableHead>
										<TableHead>Number of Entries</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{chartData.map((entry) => (
										<TableRow key={entry.label}>
											<TableCell>{entry.label}</TableCell>
											<TableCell>{entry.value.toFixed(2)}</TableCell>
											<TableCell>{entry.formattedTime}</TableCell>
											<TableCell>{entry.count}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</TabsContent>
				</CardContent>
			</Tabs>
		</Card>
	);
};

export default AverageScoreByLabel;
