import Hero from "@/features/website/components/Hero";
import Section from "@/features/website/components/Section";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

import Footwatch from "../../../features/website/foot-watch/components/Footwatch";

const DiabeticFeet = () => {
	const navigate = useNavigate();

	return (
		<>
			<Section>
				<Hero
					caption="Foot Watch"
					title="Helping Prevent Diabetic Foot Ulcers"
					description="Foot Watch allows patients to remotely monitor their Foot Health. Photo diaries of the tops and soles of each foot are shared with clinicians. AI ensures image quality and interprets the photos providing analytics and insights for clinical review."
					primaryText={"Get in Touch"}
					primaryOnClick={() => navigate({ to: "/contact" })}
				/>
			</Section>

			<Footwatch />
			{/* <Section className="mb-16">
				<p className="mx-auto px-8 md:px-24 text-center text-xl">
					Visit{' '}
					<a
						className="text-indigo-600 underline underline-offset-2"
						href="https://footwatch.co.uk"
						target="_blank"
						rel="noreferrer"
					>
						footwatch.co.uk
					</a>{' '}
					for more details.
				</p>
			</Section> */}
		</>
	);
};

export const Route = createFileRoute("/_website/use-cases/diabetic-feet")({
	component: DiabeticFeet,
});

export default DiabeticFeet;
