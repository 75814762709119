import FaqBlock from "@/features/website/components/FaqBlock";
import GetInTouch from "@/features/website/components/GetInTouch";
import Hero from "@/features/website/components/Hero";
import Section from "@/features/website/components/Section";
import { productElements as pe } from "@/features/website/pain-watch";
import SocialProof from "@/features/website/pain-watch/SocialProof";
import { benefitsContent } from "@/features/website/pain-watch/content/benefitsContent";
import { painWatchFAQs } from "@/features/website/pain-watch/content/painWatchFaqs";
import { useAuth } from "@/utils/auth";
import { createFileRoute, useNavigate } from "@tanstack/react-router";

import WrappingIconPoints from "../../../features/website/components/WrappingIconPoints";

const PainInjections = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	return (
		<>
			<Section>
				<Hero
					title="Make Better Decisions following Pain Therapy"
					caption="Pain Watch"
					description="Pain Watch provides patients with a digital diary to record pain scores following an injection. Clinicians can manage these diaries to take quick action when the time is right."
					primaryText={auth.id ? "Sign In" : "Get in Touch"}
					primaryOnClick={() =>
						auth.id
							? navigate({ to: "/profile" })
							: navigate({ to: "/contact", search: {} })
					}
				/>
			</Section>
			<SocialProof />
			{/* <Section>
				<SectionTitle caption="Problem" title="Unnecessary Follow-up Appointments" />
				<p className="mx-auto max-w-[800px] text-center text-lg">
					Over <strong>500 000</strong> injections are given annually in the UK. Surgeons routinely follow up almost
					every injection. With Patient Watch, 9 in 10 of these appointments could be avoided.
				</p>
			</Section> */}
			<pe.PatientFlow />

			{/* <Section>
				<PotentialSavings />
			</Section> */}
			<pe.DoctorFlow />
			<Section
				caption="Benefits"
				title="How Patient Watch can help"
				description={
					<p>
						Creating a better experience for patients and clinicians while
						gathering data to improve future care.
					</p>
				}
			>
				<WrappingIconPoints content={benefitsContent} />
			</Section>
			<Section>
				<GetInTouch />
			</Section>
			<Section>
				<FaqBlock FAQs={painWatchFAQs} />
			</Section>
		</>
	);
};

export const Route = createFileRoute("/_website/use-cases/pain-injections")({
	component: PainInjections,
});

export default PainInjections;
